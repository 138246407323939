import React from "react"
import blanc from '../images/blanc.jpg';
import jaune from '../images/jaune1.jpg';
import hero1 from '../images/hero1.jpg';
import hero2 from '../images/hero2.jpg';
import team from '../images/team.jpg';
import logoTxt from '../images/logotxt.png';
// import face1 from '../images/face1.png';
// import face2 from '../images/face1.png';
// import face3 from '../images/face1.png';

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />
    <div className="index">
      <section>
        <div className="fluid-container">
          <div className="row d-none d-lg-block">
            <div className="col-md-12 hero">
              <div className="heroTxt">
                <div className="">
                  <img src={logoTxt} alt="Pâtisserie Jundt-Wurtz" className="herotxtimg"/>
                  <h1>JUNDT-WURTZ</h1>
                  <h2>PÂTISSERIE</h2>
                  <div className="divider"/>
                  <h2>ECKWERSHEIM</h2>
                </div>
              </div>
              <div className="herotrig"></div>
            </div>
          </div>
          <div className="d-lg-none">
            <div className=" hero heroMobile">
              <div className="heroTxt">
                <div className="">
                  <img src={logoTxt} alt="Logo" className="herotxtimg"/>
                  <h1>JUNDT-WURTZ</h1>
                  <h2>PÂTISSERIE</h2>
                  <div className="divider" style={{margin:"24px auto"}}/>
                  <h2>ECKWERSHEIM</h2>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section>
        <div className="container d-none d-lg-block">
          <div className="row">
            <div className="col-md-9 offset-md-2">
              <div className="placezone">
                <img src={team} alt="Notre équipe"/>
                <div className="placezone-text">
                  <h2 className="times">Une passion</h2>
                  <div className="divider"></div>
                  <div className="description">
                    Boulangers-Pâtissiers de père en fils depuis plusieurs générations, nous perpétuons un savoir-faire et une passion familiale qui nous ont été transmis sans modération par mon père, Meilleur Ouvrier de France.<br/>
                    Nous travaillons avec passion et précision par amour de notre métier. Notre exigence : une pâtissière révélant des goûts et des saveurs authentiques. 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fluid-container d-lg-none">
          <div className="">
            <img src={team} alt="Notre équipe" style={{width:"100%"}}/>
          </div>
          <div className="">
            <div className="placezone placezoneMobile">
            <div className="placezone-text">
              <h2 className="times center">Une passion</h2>
              <div className="divider center"></div>
              <div className="description">
                Boulangers-Pâtissiers de père en fils depuis plusieurs générations, nous perpétuons un savoir-faire et une passion familiale qui nous ont été transmis sans modération par mon père, Meilleur Ouvrier de France.<br/>
                Nous travaillons avec passion et précision par amour de notre métier. Notre exigence : une pâtissière révélant des goûts et des saveurs authentiques. 
              </div>
            </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      <section>
        <div className="fluid-container">
          <div className="row d-none d-lg-block" >
            <div className="">
              <img src={hero1} alt="Hero" style={{width:"100%",}}/>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container d-none d-lg-block">
          <div className="row">
            <div className="col-md-6 text" style={{paddingRight:"60px"}}>
              <div className="times title" style={{color:"var(--primary)"}}>
                Nos créations
              </div>
              <div className="divider" style={{background:"var(--primary)"}}></div>
              <div className="">
                Notre philosophie est du 100 % fait maison et des produits de saison. Nous sélectionnons soigneusement nos ingrédients de notre verger, de nos ruches ou auprès de producteurs locaux. <br/>
                Des pains fabriqués à base de blés anciens sont proposés en plus de pâtisseries traditionnelles, viennoiseries, gâteaux personnalisés, glaces, entremets ainsi que des produits traiteur.
              </div>
              <a href="/patisserie" ><div className="btn" style={{marginTop:"24px"}}>
                Découvrir
              </div></a>
            </div>
            <div className="col-md-6">
              <img src={jaune} alt="Nos créations" className="imgWithbg" />
            </div>
          </div>
        </div>
        <div className="container d-lg-none">
          <div className="row">
            <div className="col-md-12 text">
              <div className="times title" style={{color:"var(--primary)", textAlign:"center"}}>
                Nos créations
              </div>
              <div className="divider center" style={{background:"var(--primary)"}}></div>
              <div className="">
                Notre philosophie est du 100 % fait maison et des produits de saison. Nous sélectionnons soigneusement nos ingrédients de notre verger, de nos ruches ou auprès de producteurs locaux. <br/>
                Des pains fabriqués à base de blés anciens sont proposés en plus de pâtisseries traditionnelles, viennoiseries, gâteaux personnalisés, glaces, entremets ainsi que des produits traiteur.
                </div>
              <div className="btn center" style={{marginTop:"24px"}}>
                Découvrir
              </div>
            </div>
            <div className="col-md-6" style={{marginTop:"48px"}}>
              <img src={jaune} alt="Nos créations" style={{width:"100%",boxShadow:"12px 12px 0px 0px rgba(var(--primary-rgb), 0.5)"}} className="imgWithbg" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="fluid-container">
          <div className="row d-none d-lg-block">
            <div className="col-md-12">
              <img src={hero2} alt="Hero2" style={{width:"100%"}}/>
            </div>
          </div>
        </div>
      </section>
      <section >
        <div className="container d-none d-lg-block">
          <div className="row">
            <div className="col-md-9 offset-md-2">
              <div className="placezone">
                <img src={blanc} alt="Savoir-faire" />
                <div className="placezone-text">
                  <h2 className="times">Notre savoir-faire</h2>
                  <div className="divider"></div>
                  <div className="description">
                   Maître-Pâtissier, nous allions tradition et créativité. Nous utilisons des techniques ancestrales et nous sommes constamment à la recherche de renouvellement, de recettes inédites et de nouveautés.                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-lg-none">
          <div className="row">
            <div className="">
              <img src={blanc} alt="Savoir-faire" style={{width:"100%"}}/>
            </div>
            <div className="">
              <div className="placezone placezoneMobile">
                <div className="placezone-text">
                  <h2 className="times center">Notre savoir-faire</h2>
                  <div className="divider center"></div>
                  <div className="description">
                    Maître-Pâtissier, nous allions tradition et créativité. Nous utilisons des techniques ancestrales et nous sommes constamment à la recherche de renouvellement, de recettes inédites et de nouveautés.                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      {/* <section >
        <div className="container">
          <div className="row">
            <div className="col-md-12 times center" style={{marginBottom:"48px"}}>
              <h2 className="time center title">Ce qu'ils disent de nous</h2>
              <div className="divider center" style={{}}></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 offset-md-0">
              <div className="comment">
                <div className="person">
                  <div className="face"><img src={face1}/></div>
                  <div className="name">Catherine B</div>
                </div>
                <div className="title">
                  Trop bon !
                </div>
                <div className="description">
                  Adèle et Mathieu, sont des personnes très agréables, sympathiques et souriantes. Accueil très chaleureux. Tout ce qui passe entre leurs mains est beau pour les yeux, excellent pour notre palais, un vrai délice : salés, sucrés, glaces. Produits frais. Je les recommande vivement
                </div>
              </div>
            </div>
            <div className="col-md-4 offset-md-0">
              <div className="comment">
                <div className="person">
                  <div className="face"><img src={face1}/></div>
                  <div className="name">Catherine B</div>
                </div>
                <div className="title">
                  Trop bon !
                </div>
                <div className="description">
                  Adèle et Mathieu, sont des personnes très agréables, sympathiques et souriantes. Accueil très chaleureux. Tout ce qui passe entre leurs mains est beau pour les yeux, excellent pour notre palais, un vrai délice : salés, sucrés, glaces. Produits frais. Je les recommande vivement
                </div>
              </div>
            </div>
            <div className="col-md-4 offset-md-0">
              <div className="comment">
                <div className="person">
                  <div className="face"><img src={face1}/></div>
                  <div className="name">Catherine B</div>
                </div>
                <div className="title">
                  Trop bon !
                </div>
                <div className="description">
                  Adèle et Mathieu, sont des personnes très agréables, sympathiques et souriantes. Accueil très chaleureux. Tout ce qui passe entre leurs mains est beau pour les yeux, excellent pour notre palais, un vrai délice : salés, sucrés, glaces. Produits frais. Je les recommande vivement
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

    </div>
  </Layout>
)

export default IndexPage
